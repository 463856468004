<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="500px"
    title="文件夹访问权限">
    <el-form
      class="t-form"
      label-width="132px"
      label-position="left"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="选中组员不可见">
		<div class="warn-text">*创建人不在选中范围内</div>
        <el-checkbox-group
          v-model="params.userId"
          v-if="userList && userList.length">
          <el-checkbox
            v-for="v in userList"
            :key="v.userId"
            :label="v.userId">{{ v.userName }}</el-checkbox>
        </el-checkbox-group>
        <span v-else class="fs16">暂无组员</span>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <el-checkbox class="all-select" v-model="allSelect">全选</el-checkbox>
	  <t-btn
	  	w="78px"
	  	h="36px"
	  	fs="14px"
	  	type="info"
	  	@click="visible = false" 
	  	class="bold">取消</t-btn>
		{{"\xa0\xa0\xa0\xa0\xa0"}}
      <!-- <t-btn @click="selectAll" class="bold" isText>全选</t-btn> -->
	  <t-btn
	    @click="commit"
	    w="78px"
	    h="36px"
	    fs="14px"
	    class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      options: null,
      params: {
        userId: [],
      },
      userList: [],
      allSelect : false
    }
  },
  watch:{
    allSelect(val){
      if( val ){
        this.params.userId = this.userList.map(u=>u.userId);
      }
    },
    params: {
      handler(val){
        if( val.userId.length !== this.userList.length ){
          this.allSelect = false;
        }else{
          this.allSelect = true;
        }
      },
      deep:true
    }
  },
  methods: {
    selectAll(){
      let arr = this.userList.map(u=>u.userId);
      this.params.userId = arr;
    },
    // 确认
    commit() {
      this.$emit('commit', { ...this.params, ...this.options })
    },
    // 显示
    show(options = null) {
      this.options = options
      this.visible = true
      let api = options.isFile ? 'getUsersDenyByFile' : 'getUsersDenyByFloder'
      this.$api.cases[api]({ id: options.id }).then(res => {
        this.userList = res.items
        this.params.userId = res.items.reduce(function(arr, v) {
          if(v.checked) {
            return arr.concat(v.userId)
          }
          return arr
        }, [])
        
      })
    },
    // 关闭重置
    closed() {
      this.options = null
      this.params = {
        userId: [],
      }
      this.userList = []
    }
  }
}
</script>
<style>
  .warn-text {
    /* position: absolute;
    top: 27px;
    left: 160px; */
    font-size: 14px;
    font-weight: 400;
    color: #BBBBBB;
  }

  .all-select {
    position: absolute;
    left: 25px;
    bottom: 28px;
  }
</style>
