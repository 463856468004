<template>
  <div class="logs-container">
    <div class="table-hd">
      <span class="hd-title">工作日志</span>
      <t-btn
        @click="createWorkLogs"
		fs="14px"
		radius="8px"
        w="106px"
        h="36px" plain>添加日志</t-btn>
    </div>
    <t-pagination
      @currentChange="currentChange"
      @sizeChange="sizeChange"
      :pages="pages">
      <el-table
        :data="list"
        slot="table"
        class="t-tabel"
        style="width: 100%"
        border>
        <div slot="empty" class="nothing-box">
          <i class="not-icon small" />
        </div>
        <el-table-column
          v-for="(v, i) in labelList"
          :key="i"
          :label="v.label"
          :min-width="v.width">
          <template slot-scope="scope">
            <span
              @click="onEdit(scope.row)"
              v-if="v.key === 'content'"
              class="pointer pointer__hover not-select ellipsis-text">
              {{ scope.row[v.key] || NOT }}
            </span>
            <span v-else-if="v.key === 'no'">
              {{ (scope.$index + 1) + (pageParams.page - 1) * pageParams.perPage }}
            </span>
            <span v-else-if="v.key === 'workDate'">
              {{scope.row.workDate}}{{"\xa0\xa0"}}{{scope.row.startTime.substring(0,5)}}-{{scope.row.endTime.substring(0,5)}}
            </span>
            <span v-else>{{ scope.row[v.key] || NOT }}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          label="操作">
          <template slot-scope="scope">
            <t-btn
              @click="del(scope.row.id)"
              type="danger"
              isText>
              删除
            </t-btn>
          </template>
        </el-table-column>
      </el-table>
    </t-pagination>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import pagination from '@/mixins/pagination'

export default {
  mixins: [pagination],
  props: {
    casesId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      labelList: [{
        label: '编号',
        width: '80',
        key: 'no'
      }, {
        label: '内容',
        width: '200',
        key: 'content'
      }, {
        label: '工作时间',
        width: '200',
        // key: 'createDate'
        key : 'workDate'
      }],
    }
  },
  watch: {
    casesId(val) {
      console.info(val)
      val && this.init()
    }
  },
  methods: {
    ...mapMutations(['SETMSGDIALOG']),
    init() {
      this.pageParams.page = 1
      this.getList()
    },
    getList() {
      const { casesId } = this

      this.$api.cases.getWorkLogs({ casesId,...this.pageParams }).then(res => {
        this.list = res.items;
        this.pages = res.page
      })
    },
    // 添加工作日志
    createWorkLogs() {
      this.$emit('create')
    },
    // 编辑工作日志
    onEdit(row) {
      this.$emit('edit', row)
    },
    // 删除
    del(id) {
      this.SETMSGDIALOG({
        title: '系统提示',
        message: `删除不会进入【回收站】，确定要该工作日志删除吗？`,
        commit: () => {
          this.$api.cases.delWorkLogs({
            id
          }).then(() => {
            if (this.list.length <= 1 && this.pageParams.page > 1) {
              this.pageParams.page = this.pageParams.page - 1
            }
            this.getList()
            this.SETMSGDIALOG(null)
          })
        }
      })
    },
  },
  created() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
  .logs-container {
    padding-top: 32px;
	
	.t-tabel{
		border-radius: 8px;
		border-color: transparent!important;
		&.el-table--border{
			border-color: transparent!important;
		}
		.el-table__header{
			background-color: #F4F7FA;
			border-radius: 8px 8px 0 0;
		}
		.el-table__body{
			
		}
	}
    .table-hd {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      .hd-title {
        font-size: 14px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #60707D;
        line-height: 20px;
      }
      .btn-box {
        display: flex;
        align-items: center;
        > div:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
    .ellipsis-text {
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      white-space: nowrap;
    }
  }
</style>