<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="550px"
    title="重命名">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="102px"
      label-position="left"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="被害人" prop="human">
        <el-input
          v-model="params.human"
          class="input-width"
          placeholder="请输入被害人姓名"/>
      </el-form-item>
      <el-form-item label="罪名" prop="name">
        <el-select
          v-model="params.name"
          placeholder="请选择罪名"
          multiple
          filterable>
          <el-option
            v-for="item in chargeList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <t-btn
      	w="78px"
      	h="36px"
      	fs="14px"
      	type="info"
      	@click="visible = false" 
      	class="bold">取消</t-btn>
      <t-btn
        @click="commit"
        w="78px"
        h="36px"
        fs="14px"
        class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    chargeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      params: {
        id: '',
        name: [],
        human: ''
      },
      rules: {
        name: [
          { required: true, message: '请选择罪名' },
        ],
        human: [
          { required: true, message: '请输入被害人姓名' },
        ],
      }
    }
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', this.params)
        }
      })
    },
    // 显示
    show(params) {
      Object.keys(this.params).forEach(v => {
        if (v === 'name') {
          this.params[v] = params[v].split(' ')
        } else {
          this.params[v] = params[v]
        }
      })
      this.visible = true
    },
    // 关闭重置
    closed() {
      this.$refs.form.resetFields()
    }
  }
}
</script>
