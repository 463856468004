<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="550px"
    title="新建文件夹">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="102px"
      label-position="left"
      size="medium"
	  @submit.native.prevent
      hide-required-asterisk>
      <el-form-item label="默认排序">
        <el-input
          v-model="caseNumber"
          class="input-width"
          readonly />
      </el-form-item>
      <el-form-item label="嫌疑人" prop="human">
        <el-input
          v-model="params.human"
          class="input-width"
          placeholder="请输入嫌疑人姓名"/>
      </el-form-item>
      <el-form-item label="罪名" prop="name">
        <el-select
          v-model="params.name"
          placeholder="请选择罪名"
          multiple
          filterable>
          <el-option
            v-for="item in chargeList"
            :key="item.id"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
     <t-btn
     	w="78px"
     	h="36px"
     	fs="14px"
     	type="info"
     	@click="visible = false" 
     	class="bold">取消</t-btn>
     <t-btn
       @click="commit"
       w="78px"
       h="36px"
       fs="14px"
       class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    chargeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: false,
      parentId: null,
      caseNumber: '',
      params: {
        name: [],
        human: ''
      },
      rules: {
        name: [
          { required: true, message: '请选择罪名' },
        ],
        human: [
          { required: true, message: '请输入嫌疑人姓名' },
        ],
      }
    }
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', { ...this.params, parentId: this.parentId})
          this.visible = false;
        }
      })
    },
    // 显示
    show(parentId = null, name = null) {
      this.visible = true
      this.parentId = parentId
      this.$api.cases.getCaseNumber({ parentId }).then(res => {
        this.caseNumber = name + '-' + res.number
      })
    },
    // 关闭重置
    closed() {
      this.parentId = null
      this.caseNumber = ''
      this.$refs.form.resetFields()
    }
  }
}
</script>
