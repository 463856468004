<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="626px"
    title="图片转PDF">
    <div class="file-table">
      <div class="table-th">
        <div class="td f-1">
          <span>图片</span>
        </div>
        <div class="td" style="width: 78px">
          <span>操作</span>
        </div>
      </div>
      <template v-if="files && files.length">
        <div v-for="(v, i) in files" :key="i" class="table-tr">
          <div class="td f-1">
            <span>{{ v.name }}</span>
          </div>
          <div class="td" style="width: 78px">
            <t-btn @click="remove(i)" type="danger" isText>移除</t-btn>
          </div>
        </div>
      </template>
      <div v-else class="table-tr">
        <div class="td td-nothing f-1">
          <span>暂无图片</span>
        </div>
      </div>
    </div>
    <div>
      <el-button @click="onChoseImage" type="text" icon="el-icon-circle-plus-outline">选择图片</el-button>
      <input @input="fileInput" ref="fileInput" accept="image/*" type="file" style="display: none;" multiple/>
    </div>
    <div class="flex jc_e ai_c pt20">
      <t-btn
      	w="78px"
      	h="36px"
      	fs="14px"
      	type="info"
      	@click="visible = false" 
      	class="bold">取消</t-btn>
      <t-btn
        @click="commit"
        w="78px"
        h="36px"
        fs="14px"
        class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
import message from '@/mixins/message'
export default {
  mixins: [message],
  data() {
    return {
      visible: false,
      files: []
    };
  },
  methods: {
    // 确认
    commit() {
      const { files } = this

      if (!files || !files.length) {
        this.error({
          message: '请至少选择一张图片'
        })
        return
      }

      this.$emit('commit', files)
    },
    // 显示
    show() {
      this.visible = true;
    },
    // 关闭重置
    closed() {
      this.files = []
    },
    // 文件选择回调
    fileInput(event) {
      const {
        target: { files }
      } = event;

      this.files = this.files.concat([...files])
      this.$refs.fileInput.value = "";
    },
    // 点击选择文件
    onChoseImage() {
      this.$refs.fileInput.click();
    },
    // 获取
    remove(index) {
      this.files = this.files.filter((v, i) => i !== index)
    }
  }
};
</script>

<style lang="scss" scoped>
.file-table {
  width: 100%;
  border-radius: 4px;
  border: 1px solid $border;
  overflow: hidden;
  .table-tr,
  .table-th {
    display: flex;
    width: 100%;
    height: 40px;
    &:not(:last-child) {
      height: 41px;
      border-bottom: 1px solid $line;
    }
    > .td {
      display: flex;
      align-items: center;
      height: 100%;
      padding: 0 20px;
      font-size: 14px;
      color: $primary_text;
      &:not(:last-child) {
        border-right: 1px solid $line;
      }
      &-nothing {
        justify-content: center;
        color: $tertiary_text;
      }
    }
  }
  .table-th {
    background: $primary_bg;
  }
}
</style>
