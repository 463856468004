<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="500px"
    title="编辑工作日志"
    class="aas">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="102px"
      label-position="left"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="工作日期" prop="workDate">
        <el-date-picker
          v-model="params.workDate"
          type="date"
          value-format="yyyy-MM-dd"
          style="width: 100%"
          placeholder="请选择工作日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="内容" prop="content">
        <el-input
          v-model="params.content"
          type="textarea"
          class="input-width"
          placeholder="请输入内容"/>
      </el-form-item>
      <el-form-item label="时间" prop="dateRang">
        <el-time-picker
          v-model="dateRang"
          style="width: 100%"
          range-separator="-"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
          value-format="HH:mm"
          format="HH:mm"
          is-range/>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <span class="mr-50">*系统将自动生成工作日志<br/>{{"\xa0"}}并存放于“委托材料、工作日志”文件夹</span>
     <t-btn
     	w="78px"
     	h="36px"
     	fs="14px"
     	type="info"
     	@click="visible = false" 
     	class="bold">取消</t-btn>
     <t-btn
       @click="commit"
       w="78px"
       h="36px"
       fs="14px"
       class="bold ml10">保存</t-btn>
    </div>
  </el-dialog>
</template>

<script>
const initParams = () => ({
  id: '',
  workDate: '',
  content: '',
  startTime: '',
  endTime: ''
})
export default {
  props: {
    chargeList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    const dateRang = (rule, value, callback) => {
      const { startTime, endTime } = this.params
      if (!startTime || !endTime) {
        callback(new Error('请选择时间范围'))
      } else {
        callback()
      }
    }
    return {
      visible: false,
      parentId: null,
      caseNumber: '',
      params: initParams(),
      rules: {
        workDate: [
          { required: true, message: '请选择工作日期' },
        ],
        content: [
          { required: true, message: '请输入内容' },
        ],
        dateRang: [
          { validator: dateRang },
        ],
      }
    }
  },
  computed: {
    dateRang: {
      get() {
        const { startTime, endTime } = this.params

        return [startTime, endTime]
      },
      set(val) {
        [this.params.startTime = '', this.params.endTime = ''] = val || []
      }
    }
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', this.params)
        }
      })
    },
    // 显示
    show(row) {
      Object.keys(this.params).forEach(v => {
        this.params[v] = row[v]
      })
      this.visible = true
    },
    // 关闭重置
    closed() {
      this.params = initParams()
      this.$refs.form.resetFields()
    }
  }
}
</script>
<style lang="scss" scoped>
    .mr-50 {
      margin-right: 50px;
    }
</style>

