<template>
  <el-dialog
    @closed="closed"
    :visible.sync="visible"
    :close-on-click-modal="false"
    width="440px"
    title="新建年份文件夹">
    <el-form
      :model="params"
      :rules="rules"
      ref="form"
      class="t-form"
      label-width="86px"
      label-position="left"
      size="medium"
      hide-required-asterisk>
      <el-form-item label="创建年份" prop="name">
        <el-date-picker
          v-model="params.name"
          style="width: 100%"
          type="year"
          value-format="yyyy"
          placeholder="请选择创建年份">
        </el-date-picker>
      </el-form-item>
    </el-form>
    <div class="flex jc_e ai_c pt20">
      <t-btn
      	w="78px"
      	h="36px"
      	fs="14px"
      	type="info"
      	@click="visible = false" 
      	class="bold">取消</t-btn>
      <t-btn
        @click="commit"
        w="78px"
        h="36px"
        fs="14px"
        class="bold ml10">确认</t-btn>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      parentId: null,
      params: {
        name: '',
      },
      rules: {
        name: [
          { required: true, message: '请选择创建年份' },
        ]
      },
    }
  },
  methods: {
    // 确认
    commit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.$emit('commit', { ...this.params, parentId: this.parentId})
        }
      })
    },
    // 显示
    show(parentId = null) {
      this.visible = true
      this.parentId = parentId
    },
    // 关闭重置
    closed() {
      this.parentId = null
      this.$refs.form.resetFields()
    }
  }
}
</script>
