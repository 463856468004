<template>
    <el-dialog @closed="closed" :visible.sync="visible" :close-on-click-modal="false" width="440px" title="新建文件夹">
        <el-form :model="params" :rules="rules" ref="form" class="t-form" label-width="102px" label-position="left"
            size="medium" @submit.native.prevent hide-required-asterisk>
            <el-form-item label="文件夹名称" prop="name">
               
            </el-form-item>
        </el-form>
        <div class="flex jc_e ai_c pt20">
            <t-btn w="78px" h="36px" fs="14px" type="info" @click="visible = false" class="bold">取消</t-btn>
            <t-btn @click="commit" w="78px" h="36px" fs="14px" class="bold ml10">确认</t-btn>
        </div>
    </el-dialog>
</template>
<script>
export default {
    data() {
        return {
            visible: false,
            parentId: null,
            params: {
                name: '',
            },
            rules: {
                name: [
                    { required: true, message: '请选择年份文件夹' },
                ]
            },
        }
    },
    methods: {
        // 确认
        commit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.$emit('commit', { ...this.params, parentId: this.parentId })
                }
            })
        },
        // 显示
        show(parentId) {
            this.visible = true
            this.parentId = parentId
        },
        // 关闭重置
        closed() {
            this.parentId = null
            this.$refs.form.resetFields()
        }
    }
}
</script>
  